<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <div class="caz-blocks-sarcho-title mb-2">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">Платежи за расходы</div>
              <div class="block-sarche">
                <div class="header__search">
                    <el-date-picker
                        v-model="filterForm.date_interval"
                        type="daterange"
                        size="mini"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        range-separator="/"
                        :start-placeholder="$t('message.begin_date')"
                        :end-placeholder="$t('message.end_date')"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm">
                    </el-date-picker>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="flex-style text-right " :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <el-button size="small" class="btu-color" @click="drawerCreate = true">
              {{ $t('message.outgoing_cost') }}
            </el-button>
            <el-button size="small" class="btu-color" @click="controlExcelData()">
             Excel
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
                <el-button
                    class="padding_none"
                    size="small"
                    icon="el-icon-open"
                ></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(column, index) in columns"
                        :key="'drop' + index"
                    >
                        <el-checkbox
                            :checked="column.show"
                            @change="check(column.column, $event)"
                            >{{ column.title }}
                        </el-checkbox>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->


        <div class="w-100 d-flex align-center  justify-center">
            <span class="align-items-center align-self-center pr-2">
                <span class="el-tag el-tag--light custom-font-size-16" :class="mode ? 'price__day' : 'price__night blue__night'">
                    <!-- Общая сумма Входящий: 3 286 251 000 Сум -->
                    {{ $t('message.total_amount') }}: {{ total | formatMoney(1) }} 
                </span>
            </span>
        </div>

        <div class="card-table-header table-crm-smart">
            <table
                class="table-my-code table-bordered"
                :class="mode ? 'table__myday' : 'table__mynight'"
                v-loading="loadingData"
            >
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.cost_type_id.show">
                            {{ columns.cost_type_id.title }}
                        </th>
                        <th v-if="columns.bank_account_id.show">
                            {{ columns.bank_account_id.title }}
                        </th>
                        <th v-if="columns.money_amount.show">
                            {{ columns.money_amount.title }}
                        </th>
                        <th v-if="columns.currency_rate.show">
                            {{ columns.currency_rate.title }}
                        </th>
                        <th v-if="columns.currency.show">
                            {{ columns.currency.title }}
                        </th>
                        <th v-if="columns.payment_type_id.show">
                            {{ columns.payment_type_id.title }}
                        </th>
                        <th v-if="columns.date.show">
                            {{ columns.date.title }}
                        </th>
                        <th v-if="columns.comment.show">
                            {{ columns.comment.title }}
                        </th>
                        <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>

                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th>

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.cost_type_id.show">
                            <select-cost-type
                                :class="mode ? 'input__day' : 'input__night'"
                                :size="'mini'"
                                :placeholder="columns.cost_type_id.title"
                                :id="filterForm.cost_type_id"
                                v-model="filterForm.cost_type_id"
                                >
                            </select-cost-type>
                        </th>
                        <th v-if="columns.bank_account_id.show">
                            <select-bank-account
                                :class="mode ? 'input__day' : 'input__night'"
                                :size="'mini'"
                                :placeholder="columns.bank_account_id.title"
                                :id="filterForm.bank_account_id"
                                v-model="filterForm.bank_account_id"
                                >
                            </select-bank-account>
                        </th>
                        <th v-if="columns.money_amount.show">
                            <crm-input
                                :placeholder="columns.money_amount.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.money_amount"
                            ></crm-input>
                        </th>
                        <th v-if="columns.currency_rate.show">
                            <crm-input
                                disabled
                                :placeholder="columns.currency_rate.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.currency_rate"
                            ></crm-input>
                        </th>
                        <th v-if="columns.currency.show">
                            <select-currency
                                :size="'medium'"
                                ref="currencySelect"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.currency.title"
                                :id="filterForm.currency_id"
                                v-model="filterForm.currency_id"
                                >
                            </select-currency>
                        </th>
                        <th v-if="columns.payment_type_id.show">
                            <select-payment-type
                                :class="mode ? 'input__day' : 'input__night'"
                                :size="'mini'"
                                :placeholder="columns.payment_type_id.title"
                                :id="filterForm.payment_type_id"
                                v-model="filterForm.payment_type_id"
                                >
                            </select-payment-type>
                        </th>
                        <th v-if="columns.date.show">
                            <el-date-picker
                                :class="mode ? 'input__day' : 'input__night'"
                                size="mini"
                                v-model="filterForm.date"
                                type="datetime"
                                :placeholder="$t('message.date')"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                            ></el-date-picker>
                        </th>
                        <th v-if="columns.comment.show">
                            <crm-input
                                :placeholder="columns.comment.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.comment"
                            ></crm-input>
                        </th>

                        <th v-if="columns.created_at.show">
                            <crm-date-picker
                                :placeholder="columns.created_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.created_at"
                            ></crm-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <crm-date-picker
                                :placeholder="columns.updated_at.title"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                v-model="filterForm.updated_at"
                            ></crm-date-picker>
                        </th>

                        <th
                            class="settinW"
                            v-if="columns.settings.show"
                        ></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr
                        v-for="costTransaction in list"
                        :key="costTransaction.id"
                        class="cursor-pointer"
                    >
                        <td v-if="columns.id.show">
                            {{ costTransaction.id }}
                        </td>

                        <td v-if="columns.cost_type_id.show">
                            {{ costTransaction.costType ? costTransaction.costType.name : '' }}
                        </td>

                        <td v-if="columns.bank_account_id.show">
                            {{ costTransaction.bankAccount ? costTransaction.bankAccount.name : '' }}
                        </td>

                        <td v-if="columns.money_amount.show">
                           <span class="bold__money"> {{ costTransaction.money_amount | formatNumber(1) }}</span>
                        </td>

                        <td v-if="columns.currency_rate.show">
                            {{ costTransaction.currency_rate }}
                        </td>

                        <td v-if="columns.currency.show">
                            {{ costTransaction.bankAccount ? costTransaction.bankAccount.currency.code : '' }}
                        </td>

                        <td v-if="columns.payment_type_id.show">
                            {{ costTransaction.paymentType ? costTransaction.paymentType.name : '' }}
                        </td>

                        <td v-if="columns.date.show">
                            {{ costTransaction.date }}
                        </td>

                        <td v-if="columns.comment.show">
                            {{ costTransaction.comment }}
                        </td>

                        <td v-if="columns.created_at.show">
                            {{ costTransaction.created_at }}
                        </td>

                        <td v-if="columns.updated_at.show">
                            {{ costTransaction.updated_at }}
                        </td>

                        <td
                            v-if="columns.settings.show"
                            class="settings-td"
                        >
                            <crm-settings
                                :name="$options.name"
                                :model="costTransaction"
                                :actions="actions"
                                :permissionShow="'costTransactions.update'"
                                :permissionDestroy="'costTransactions.delete'"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-settings>
                        </td>
                    </tr>
                </transition-group>
            </table>
            <div class="my___pagination">
                <crm-pagination
                    @c-change="updatePagination"
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    :pagination="pagination"
                ></crm-pagination>
                <!-- <Pagination /> -->
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg scrol-ii">
            <el-drawer
                :with-header="false"
                :visible.sync="drawerCreate"
                ref="drawerCreate"
                size="70%"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <div>
                    <crm-create
                        ref="drawerCreateChild"
                        drawer="drawerCreate"
                    >
                    </crm-create>
                </div>
            </el-drawer>

            <el-drawer
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import selectPaymentType from "@/components/inventory/select-paymentType";
import selectBankAccount from "@/components/inventory/select-bank-account";
import selectCostType from "@/components/inventory/select-cost-type";
import selectCurrency from "@/components/selects/select-currency";


export default {
    name: "costTransaction",
    mixins: [list],
    components: {
        selectPaymentType,
        selectBankAccount,
        selectCostType,
        Pagination,
        CrmCreate,
        CrmUpdate,
        selectCurrency,
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            list: "costTransactions/list",
            columns: "costTransactions/columns",
            pagination: "costTransactions/pagination",
            filter: "costTransactions/filter",
            sort: "costTransactions/sort",
            total: "costTransactions/total",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "costTransactions/index",
            setPagination: "costTransactions/setPagination",
            updateSort: "costTransactions/updateSort",
            updateFilter: "costTransactions/updateFilter",
            updateColumn: "costTransactions/updateColumn",
            updatePagination: "costTransactions/updatePagination",
            show: "costTransactions/show",
            empty: "costTransactions/empty",
            delete: "costTransactions/destroy",
            refreshData: "costTransactions/refreshData",
        }),
        check: function (column, event) {
            this.$emit("c-change", {
                key: column,
                value: event,
            });
        },
    },
};


</script>
<style>
    .custom-font-size-16{
        font-size: 16px;
    }
    .bold__money{
        font-weight: bolder;
        font-size: 13px;
    }
</style>
